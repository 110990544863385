import config from "../config";
import { PATHS } from "../constants/Constants";

function getBaseUrl(): string {
	return config.BASE_URL;
}

function getApiUrl(): string {
	return `${config.API_URL}/api`;
}

function redirectToLogin() {
	window.location.assign(`${getBaseUrl()}${PATHS.LOGIN}`);
}

export default {
	getBaseUrl,
	getApiUrl,
	redirectToLogin,
};
