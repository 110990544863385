import { User } from "./routes/UserManager";

export const LOGIN_FAILED_MESSAGE = "Login failed";
export const LOGIN_SUCCESS_MESSAGE = "Login successful";
export const USER_DISABLED_MESSAGE = "User is disabled";
export const USER_NOT_FOUND_MESSAGE = "User not found";
export const INVALID_PASSWORD_MESSAGE = "Invalid password";
export const EMAIL_NOT_VERIFIED_MESSAGE = "Email not verified";
export const NUMBER_NOT_VERIFIED_MESSAGE = "Number not verfied";
export const RESET_PASSWORD_BEFORE_LOGIN_MESSAGE = "Reset password before login";
export const EMAIL_AND_NUMBER_NOT_VERIFIED_MESSAGE = "Email and number not verfied";


export const isLocalhost = window.location.hostname === 'localhost';
export const BASE_URL = isLocalhost ? "http://localhost:3001/api/v1" : "https://api.sequence.sg/api/v1";
export const SEND_EMAIL_URL = BASE_URL + "/send-email";
export const LOGIN_URL = BASE_URL + "/login";
export const SIGN_UP_URL = BASE_URL + "/signup";
export const RESET_PASSWORD_URL = BASE_URL + "/reset-password";
export const FORGOT_PASSWORD_URL = BASE_URL + "/forgot-password";
export const RESET_PASSWORD_FROM_ID_URL = BASE_URL + "/reset-password-from-id";
export const UPDATE_STATUS_URL = BASE_URL + "/update-status";
export const SET_USER_IN_ACTIVE = BASE_URL + "/set-inactive";
export const UPDATE_GROUP_URL = BASE_URL + "/update-groups";
export const UPDATE_PERMISSION_URL = BASE_URL + "/update-permissions";
export const GET_ACRA_BUSINESS_DETAILS_URL = BASE_URL + "/acraBusinessDetails";



export const PRIMARY_COLOR = "#7201a9";
export const SECONDARY_BG_SHADE_COLOR = "#f8eafe";
export const THIRD_BG_SHADE_COLOR = "#f2dafe";
export const BG_SHADE_COLOR = "#7e7ab7";
export const WHATSAPP_GREEN_COLOR = "#25d366";
export const OSOME_BEIGE_COLOR = "#f3ede6";
export const YELLOW_BG_COLOR = "#ffe2a3";

export const PRIMARY_FONT = "Moderustic";


export const sampleUsers: User[] = [
    { email: "alice@example.com", number: "9876543210", userType: "staff" },
    { email: "bob@admin.com", number: "9876543211", userType: "staff_admin" },
    { email: "charlie@company.com", number: "9876543212", userType: "administrator" },
    { email: "dave@client.com", number: "9876543213", userType: "client" },
    { email: "eve@vendor.com", number: "9876543214", userType: "vendor" },
    { email: "frank@example.com", number: "9876543215", userType: "staff" },
    { email: "grace@admin.com", number: "9876543216", userType: "staff_admin" },
    { email: "heidi@company.com", number: "9876543217", userType: "administrator" },
    { email: "ivan@client.com", number: "9876543218", userType: "client" },
    { email: "judy@vendor.com", number: "9876543219", userType: "vendor" },
    { email: "karen@example.com", number: "9876543220", userType: "staff" },
    { email: "leo@admin.com", number: "9876543221", userType: "staff_admin" },
    { email: "mike@company.com", number: "9876543222", userType: "administrator" },
    { email: "nina@client.com", number: "9876543223", userType: "client" },
    { email: "oscar@vendor.com", number: "9876543224", userType: "vendor" },
  ];
  

export const sampleTicketValues = [
  {
      ticketId: 'TKT-1001',
      entityObject: { entityName: 'Acme Corp' },
      ticketDescription: 'Unable to access account settings',
      status: 'Open'
  },
  {
      ticketId: 'TKT-1002',
      entityObject: { entityName: 'Globex Inc' },
      ticketDescription: 'Billing discrepancy issue',
      status: 'Pending'
  },
  {
      ticketId: 'TKT-1003',
      entityObject: { entityName: 'Umbrella LLC' },
      ticketDescription: 'System error in dashboard',
      status: 'Resolved'
  },
  {
      ticketId: 'TKT-1004',
      entityObject: { entityName: 'Initech' },
      ticketDescription: 'Login issues with multi-factor authentication',
      status: 'Open'
  },
  {
      ticketId: 'TKT-1005',
      entityObject: { entityName: 'Soylent Corp' },
      ticketDescription: 'Request for data migration',
      status: 'Closed'
  },
  {
      ticketId: 'TKT-1006',
      entityObject: { entityName: 'Stark Industries' },
      ticketDescription: 'Access request for new team member',
      status: 'Pending'
  },
  {
      ticketId: 'TKT-1007',
      entityObject: { entityName: 'Wayne Enterprises' },
      ticketDescription: 'Software update failure',
      status: 'Open'
  },
  {
      ticketId: 'TKT-1008',
      entityObject: { entityName: 'Hooli' },
      ticketDescription: 'API rate limit issue',
      status: 'Resolved'
  },
  {
      ticketId: 'TKT-1009',
      entityObject: { entityName: 'Wonka Industries' },
      ticketDescription: 'Error processing batch files',
      status: 'Open'
  },
  {
      ticketId: 'TKT-1010',
      entityObject: { entityName: 'Duff Beer Co' },
      ticketDescription: 'Issue with automated reporting',
      status: 'Pending'
  },
  {
      ticketId: 'TKT-1011',
      entityObject: { entityName: 'Oceanic Airlines' },
      ticketDescription: 'Access request for external contractor',
      status: 'Closed'
  },
  {
      ticketId: 'TKT-1012',
      entityObject: { entityName: 'Cyberdyne Systems' },
      ticketDescription: 'Security alert for unauthorized login attempts',
      status: 'Open'
  },
  {
      ticketId: 'TKT-1013',
      entityObject: { entityName: 'Tyrell Corp' },
      ticketDescription: 'User unable to reset password',
      status: 'Pending'
  },
  {
      ticketId: 'TKT-1014',
      entityObject: { entityName: 'MomCorp' },
      ticketDescription: 'Request for custom report generation',
      status: 'Resolved'
  },
  {
      ticketId: 'TKT-1015',
      entityObject: { entityName: 'Rich Industries' },
      ticketDescription: 'Database access issue',
      status: 'Closed'
  },
  {
      ticketId: 'TKT-1016',
      entityObject: { entityName: 'Nimbus Corp' },
      ticketDescription: 'Email notifications not received',
      status: 'Open'
  },
  {
      ticketId: 'TKT-1017',
      entityObject: { entityName: 'Oscorp' },
      ticketDescription: 'Error with payment gateway integration',
      status: 'Resolved'
  },
  {
      ticketId: 'TKT-1018',
      entityObject: { entityName: 'LexCorp' },
      ticketDescription: 'Incorrect user permissions',
      status: 'Pending'
  },
  {
      ticketId: 'TKT-1019',
      entityObject: { entityName: 'Daily Planet' },
      ticketDescription: 'Lost data during backup process',
      status: 'Closed'
  },
  {
      ticketId: 'TKT-1020',
      entityObject: { entityName: 'Stark Resilient' },
      ticketDescription: 'Request for API key rotation',
      status: 'Open'
  }
];
