import { Ticket, TicketStatus, User } from "./Ticket.type";

export enum TicketStream {
    CORP_SEC = 'CorpSec',
    ACCOUNTING_AUDIT = 'AccountsAndAudit'
}

export enum TicketType {
    EXTERNAL = 'External',
    INTERNAL = 'Internal'
}

export class EntityObject {
    constructor(
        public clientId: string,
        public UEN: string,
        public entityName: string
    ) {}
}

export class CurrentAction {
    constructor(
        public currentActionId: string,
        public currentActionContent: string,
        public listOfFiles:string[],
        public currentActionDeadLineDateTime: Date
    ) {}
}

export class SeqTicket extends Ticket {
    constructor(
        ticketId: string,
        status: TicketStatus,
        ticketRaisedDateTime: Date,
        ticketClosedDateTime: Date | null,
        ticketDescription: string,
        ticketCreatedBy: User,
        public entityObject: EntityObject,
        public workOrderNumber: string,
        public ticketType: TicketType,
        public ticketStream: TicketStream,
        public ticketRaisedBy: User,
        public ticketOwner: User,
        public overallDateTime: Date,
        public currentActionList: CurrentAction[],
        public notes: string
    ) {
        super(ticketId, status, ticketRaisedDateTime, ticketClosedDateTime, ticketDescription, ticketCreatedBy);
    }
}
