import { LoginResponse } from "../types/Login.type";

export function isValidEmail(email:string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  export function isValidPassword(password:string) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    return passwordRegex.test(password);
  }

  export function selectRandomOption(options:Array<string>) {
    const randomIndex = Math.floor(Math.random() * options.length);
    return options[randomIndex];
  }

  export function getNameFromEmail(email: string): string {
    const localPart = email.split('@')[0];
    const nameParts = localPart.split('.').slice(0, 2);
    const capitalizedParts = nameParts.map(part =>
      part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    );
    return capitalizedParts.join(' ');
  }
  
  export const storeUserData = (userDetails: LoginResponse): void => {
    localStorage.setItem('userData', JSON.stringify(userDetails));
  };

  export const getUserData = (): { userDetails: LoginResponse } | null => {
    const data = localStorage.getItem('userData');
    if (data) {
      return JSON.parse(data);
    }
    
    return null;
  };

  export const setNewToken = (newToken: string): boolean => {
    const data = localStorage.getItem('userData');
    if (data) {
      const userData = JSON.parse(data);
      userData.token = newToken;
      localStorage.setItem('userData', JSON.stringify(userData));
      return true;
    }

    return false;
  };

