import React, { useEffect, useState } from 'react';
import { Box, Button, Input, VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, useToast, Spinner, Divider, Heading } from '@chakra-ui/react';
import axios from 'axios';
import ReactJson from 'react-json-view';
import { GET_ACRA_BUSINESS_DETAILS_URL } from '../constant';
import { sampleEntities } from './Acra/SampleEntities';
import EntityView, { EntityProps } from './Acra/EntityView';

const ApiSearchComponent: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<EntityProps | undefined>();
  const [data, setData] = useState<any[]>([]);
  const toast = useToast();

  useEffect(() => { setEntity(undefined) },[query]);
  const handleSearch = () => {
    if (!query) {
      toast({
        title: 'Empty Query',
        description: 'Please enter a search query.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    setData([]); // Clear previous results
    try {
        // setTimeout(async () => {
        //     let response = await axios.get(GET_ACRA_BUSINESS_DETAILS_URL);
        //     setData(response.data);
        // },4000);
        setTimeout(() => {
          setEntityValue();
        }, 4000);
        
    } catch (error) {
      console.error('Error fetching data:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const setEntityValue = () => {
    const entity = sampleEntities.find((entity) => entity.uen === query);
    if(entity){
      setEntity(entity);
    }else{
      toast({
        title: 'No Entity',
        description: 'No entity found',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    if (data.length > 0) {
      setData([]); // Clear data when editing input
    }
  };

  return (
    <Box width="60%" mx="auto" p="4" boxShadow="lg" borderRadius="md" borderColor="#7201a9" borderWidth="1px" mt="100px">
      <VStack spacing={4}>
        <Heading> Acra Entity Details </Heading>
        <Input
          placeholder="Enter your search query..."
          value={query}
          onChange={handleInputChange}
          focusBorderColor="#7201a9"
          size="lg"
        />
        <Button
          colorScheme="purple"
          bg="#7201a9"
          _hover={{ bg: '#58007d' }}
          onClick={() => {handleSearch()}}
          isLoading={loading}
        >
          Search
        </Button>
        {loading && <Divider borderColor="#7201a9" width="100%" mt={2} />}
      </VStack>


      {entity? <EntityView {...entity}/>:null}
      {data.length > 0 && (
        <Accordion allowToggle mt="6">
          {data.map((item, index) => (
            <AccordionItem key={index} border="1px solid #7201a9" mt="20px">
              <AccordionButton _expanded={{ bg: '#7201a9', color: 'white' }}>
                <Box flex="1" textAlign="left">
                  Result #{index + 1}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <ReactJson src={item} theme="monokai" collapsed={1} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Box>
  );
};

export default ApiSearchComponent;
