import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    IconButton,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
  } from "@chakra-ui/react";
  import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
  import { useState, useEffect } from "react";
  import OrgChart from "@balkangraph/orgchart.js"; // OrgChart component from Balkangraph
import Header from "../components/Header";
  
  interface CardProps {
    title: string;
    onClick: () => void;
  }
  
  const Card = ({ title, onClick }: CardProps) => (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      textAlign="center"
      onClick={onClick}
      _hover={{ cursor: "pointer", bg: "gray.100" }}
    >
      <IconButton aria-label="Tool Icon" icon={<ExternalLinkIcon />} mb={4} />
      <Text>{title}</Text>
    </Box>
  );
  
  const TechnologyDashboard = () => {
    const {
      isOpen: isToolModalOpen,
      onOpen: onToolModalOpen,
      onClose: onToolModalClose,
    } = useDisclosure();
    const {
      isOpen: isLibraryModalOpen,
      onOpen: onLibraryModalOpen,
      onClose: onLibraryModalClose,
    } = useDisclosure();
    const {
      isOpen: isAddPersonOpen,
      onOpen: onAddPersonOpen,
      onClose: onAddPersonClose,
    } = useDisclosure();
  
    const [tools, setTools] = useState([
      "Slack",
      "Confluence",
      "Jira",
      "Google Workspace",
      "Cost Sheet",
      "Github",
    ]);
    const [projects, setProjects] = useState([
      "Project A",
      "Project B",
      "Project C",
    ]);
    const [newTool, setNewTool] = useState("");
    const [newProject, setNewProject] = useState("");
    const [newPerson, setNewPerson] = useState({ name: "", number: "", email: "" });
  
    useEffect(() => {
        const orgChartContainer = document.getElementById("org-chart-container");
        if(orgChartContainer){
            const chart = new OrgChart(document.getElementById("org-chart-container")??"", {
                nodes: [
                  { id: 1, name: "Ivan Yap", title: "CEO", email: "Ivan@example.com", number: "1234567890", joined: "2020-01-15" },
                  { id: 2, pid: 1, name: "Andrew", title: "COO", email: "Andrew@example.com", number: "1234567891", joined: "2020-02-20" },
                  { id: 3, pid: 1, name: "Abhijit", title: "CFO", email: "Abhijit@example.com", number: "1234567892", joined: "2020-03-12" },
                  { id: 4, pid: 1, name: "Mushir", title: "Lead Engineer", email: "Mushir@example.com", number: "1234567892", joined: "2020-03-12" },
                  { id: 5, pid: 2, name: "Paras", title: "SSE", email: "Paras@example.com", number: "1234567893", joined: "2021-05-22" },
                  { id: 6, pid: 3, name: "Emma", title: "Account Manager", email: "Emma@example.com", number: "1234567894", joined: "2021-06-10" },
                ],
                nodeBinding: {
                  field_0: "name",
                  field_1: "title",
                  field_2: "email",
                  field_3: "number",
                },
                enableSearch: true,
                enableDragDrop: true,
              });
          
              // Re-render chart if necessary
              return () => chart.destroy();
        }
      
    }, []);
  
    const handleAddTool = () => {
      setTools([...tools, newTool]);
      setNewTool("");
      onToolModalClose();
    };
  
    const handleAddProject = () => {
      setProjects([...projects, newProject]);
      setNewProject("");
      onLibraryModalClose();
    };
  
    const handleAddPerson = () => {
      // Add logic to insert the new person in the org chart
      setNewPerson({ name: "", number: "", email: "" });
      onAddPersonClose();
    };
  
    return (
      <Box>
        <Header/>
        <Box p={8}  mt="100px">
          <Heading mb={6}>Technology Dashboard</Heading>
    
          {/* Collaborative Tools Section */}
          <Box mb={8}>
            <Heading size="md" mb={4}>
              Collaborative Tools
            </Heading>
            <SimpleGrid columns={3} spacing={4}>
              {tools.map((tool, index) => (
                <Card key={index} title={tool} onClick={onToolModalOpen} />
              ))}
            </SimpleGrid>
            <Button mt={4} leftIcon={<AddIcon />} onClick={onToolModalOpen}>
              Add Tool
            </Button>
          </Box>
    
          {/* Projects Section */}
          <Box mb={8}>
            <Heading size="md" mb={4}>
              Projects
            </Heading>
            <SimpleGrid columns={3} spacing={4}>
              {projects.map((project, index) => (
                <Box key={index} borderWidth="1px" borderRadius="lg" p={4}>
                  <Text mb={2} as="a" href={`#${project}`}>
                    {project}
                  </Text>
                  <Button size="sm" onClick={onLibraryModalOpen}>
                    Libraries
                  </Button>
                </Box>
              ))}
            </SimpleGrid>
            <Button mt={4} leftIcon={<AddIcon />} onClick={onLibraryModalOpen}>
              Add Project
            </Button>
          </Box>
    
          {/* Organization Chart Section */}
          <Box mb={8}>
            <Heading size="md" mb={4}>
              Organization Chart
            </Heading>
            <div id="org-chart-container" style={{ width: "100%", height: "500px" }}></div>
            <Button mt={4} leftIcon={<AddIcon />} onClick={onAddPersonOpen}>
              Add Person
            </Button>
          </Box>
    
          {/* Tool Modal */}
          <Modal isOpen={isToolModalOpen} onClose={onToolModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Tool</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Tool Name"
                  value={newTool}
                  onChange={(e) => setNewTool(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleAddTool}>
                  Save
                </Button>
                <Button variant="ghost" onClick={onToolModalClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
    
          {/* Project Modal */}
          <Modal isOpen={isLibraryModalOpen} onClose={onLibraryModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Project</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Project Name"
                  value={newProject}
                  onChange={(e) => setNewProject(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleAddProject}>
                  Save
                </Button>
                <Button variant="ghost" onClick={onLibraryModalClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
    
          {/* Add Person Modal */}
          <Modal isOpen={isAddPersonOpen} onClose={onAddPersonClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Person to Hierarchy</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Name"
                  value={newPerson.name}
                  onChange={(e) =>
                    setNewPerson({ ...newPerson, name: e.target.value })
                  }
                  mb={3}
                />
                <Input
                  placeholder="Number"
                  value={newPerson.number}
                  onChange={(e) =>
                    setNewPerson({ ...newPerson, number: e.target.value })
                  }
                  mb={3}
                />
                <Input
                  placeholder="Email"
                  value={newPerson.email}
                  onChange={(e) =>
                    setNewPerson({ ...newPerson, email: e.target.value })
                  }
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleAddPerson}>
                  Save
                </Button>
                <Button variant="ghost" onClick={onAddPersonClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
      
    );
  };
  
  export default TechnologyDashboard;
  