import React, { useRef, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Card,
    Center,
    Flex,
    Box,
    Text
} from '@chakra-ui/react';
import { PRIMARY_COLOR, sampleTicketValues } from '../constant';
import { SeqTicket } from '../types/Ticketing/SeqTicket.type';
import sampleTicketList from '../types/Ticketing/SampleTickets';

const TicketTable = (props:any) => {
    const {
        handleCreateTicket
      } = props;
    const [selectedTicket, setSelectedTicket] = useState<SeqTicket | null>(null);
    const { isOpen: isViewModalOpen, onOpen: onViewModalOpen, onClose: onViewModalClose } = useDisclosure();
    const { isOpen: isDeleteDialogOpen, onOpen: onDeleteDialogOpen, onClose: onDeleteDialogClose } = useDisclosure();
    const [ticketToDelete, setTicketToDelete] = useState<SeqTicket | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sampleTickets, setSampleTickets] = useState<Array<SeqTicket>>(sampleTicketList);

    const deleteAlert = useRef(null);

    const handleView = (ticket: SeqTicket) => {
        setSelectedTicket(ticket);
        onViewModalOpen();
    };

    const handleDelete = (ticket: SeqTicket) => {
        setTicketToDelete(ticket);
        onDeleteDialogOpen();
    };

    const confirmDelete = () => {
        // Add your delete logic here (e.g., removing the ticket from state)
        console.log('Deleting ticket:', ticketToDelete?.ticketId);
        setTicketToDelete(null);
        onDeleteDialogClose();
    };
    const ticketsPerPage = 5;
    const lastIndex = currentPage * ticketsPerPage;
    const firstIndex = lastIndex - ticketsPerPage;
    const currentTickets = sampleTickets.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(sampleTickets.length / ticketsPerPage);

    return (
        <>
        <Box p={4} mt="80px">
        <Center>
            <Flex w={"80%"}>
                <Button bg={PRIMARY_COLOR} _hover={{bg:PRIMARY_COLOR}} color="white" mb={4} onClick={handleCreateTicket}>
                    Create Ticket
                </Button>
            </Flex>
        </Center>
        <Center>
            <Card w={"80%"} boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" p="30px">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Ticket ID</Th>
                            <Th>Entity Name</Th>
                            <Th>Ticket Description</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {sampleTickets.map(ticket => (
                            <Tr key={ticket.ticketId}>
                                <Td>{ticket.ticketId}</Td>
                                <Td>{ticket.entityObject?.entityName}</Td>
                                <Td>{ticket.ticketDescription}</Td>
                                <Td>{ticket.status}</Td>
                                <Td>
                                    <Button colorScheme="blue" onClick={() => handleView(ticket)} mr={2}>
                                        View
                                    </Button>
                                    <Button colorScheme="yellow" mr={2}>
                                        Edit
                                    </Button>
                                    <Button colorScheme="red" onClick={() => handleDelete(ticket)}>
                                        Delete
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Flex mt={4} justify="center" align="center">
                    <Button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    >
                    Previous
                    </Button>
                    <Text mx={4}>
                    Page {currentPage} of {totalPages}
                    </Text>
                    <Button
                    onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    >
                    Next
                    </Button>
                </Flex>
            </Card>
        </Center>

            {/* View Modal */}
            <Modal isOpen={isViewModalOpen} onClose={onViewModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ticket Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedTicket && (
                            <>
                                <p><strong>Ticket ID:</strong> {selectedTicket.ticketId}</p>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={onViewModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <AlertDialog isOpen={isDeleteDialogOpen} onClose={onDeleteDialogClose} leastDestructiveRef={deleteAlert}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Ticket
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete this ticket? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onDeleteDialogClose}>Cancel</Button>
                            <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
        </>
    );
};

export default TicketTable;
