// src/theme.ts
import { extendTheme } from '@chakra-ui/react';
import { PRIMARY_FONT } from './constant';

const theme = extendTheme({
  fonts: {
    heading: `${PRIMARY_FONT}`,
    body: `${PRIMARY_FONT}`,
  },
});

export default theme;
