export const sampleEntities = [
      {
        "name": "XYZ Pvt Ltd",
        "formerNames": ["LMN Corp", "OPQ Ltd"],
        "uen": "88888888A",
        "status": {
          "description": "Active"
        },
        "registeredAddress": {
          "unitNo": "101",
          "levelNo": "05",
          "blkhseNo": "123B",
          "streetName": "MARINA BAY ROAD",
          "postalCode": "123456"
        },
        "ssic": {
          "code": "46900",
          "description": "WHOLESALE TRADE",
          "otherDescription": "INTERNATIONAL IMPORT AND EXPORT"
        }
      },
      {
        "name": "PQR Holdings",
        "formerNames": ["RST Ltd"],
        "uen": "77777777C",
        "status": {
          "description": "Deregistered"
        },
        "registeredAddress": {
          "unitNo": "202",
          "levelNo": "03",
          "blkhseNo": "456D",
          "streetName": "ORCHARD AVENUE",
          "postalCode": "654321"
        },
        "ssic": {
          "code": "55101",
          "description": "HOTELS WITH RESTAURANT",
          "otherDescription": "HOTEL AND LODGING SERVICES"
        }
      },
      {
        "name": "Alpha Beta Co.",
        "formerNames": ["Gamma Ventures"],
        "uen": "66666666B",
        "status": {
          "description": "Active"
        },
        "registeredAddress": {
          "unitNo": "303",
          "levelNo": "07",
          "blkhseNo": "789C",
          "streetName": "RAFFLES PLACE",
          "postalCode": "111111"
        },
        "ssic": {
          "code": "70209",
          "description": "BUSINESS CONSULTANCY SERVICES",
          "otherDescription": "MANAGEMENT AND STRATEGIC CONSULTING"
        }
      },
      {
        "name": "Innovatech Solutions",
        "formerNames": ["Tech Innovations Ltd"],
        "uen": "55555555D",
        "status": {
          "description": "Live Company"
        },
        "registeredAddress": {
          "unitNo": "404",
          "levelNo": "09",
          "blkhseNo": "567E",
          "streetName": "TECH PARK AVENUE",
          "postalCode": "222222"
        },
        "ssic": {
          "code": "62021",
          "description": "SOFTWARE DEVELOPMENT",
          "otherDescription": "IT CONSULTANCY AND SYSTEM INTEGRATION"
        }
      },
      {
        "name": "Green World Pte Ltd",
        "formerNames": ["Eco Ventures"],
        "uen": "44444444E",
        "status": {
          "description": "Struck Off"
        },
        "registeredAddress": {
          "unitNo": "505",
          "levelNo": "10",
          "blkhseNo": "678F",
          "streetName": "GREEN VALLEY ROAD",
          "postalCode": "333333"
        },
        "ssic": {
          "code": "81301",
          "description": "LANDSCAPE PLANTING",
          "otherDescription": "GARDENING AND LANDSCAPING SERVICES"
        }
      }
    ];
  