import React from 'react';
import { Box, Text, Stack, Divider, Flex, Icon, VStack } from '@chakra-ui/react';
import { FaBuilding, FaAddressCard, FaCodeBranch } from 'react-icons/fa';

// Define the props for each entity based on the sample data structure
export interface EntityProps {
  name: string;
  formerNames: string[];
  uen: string;
  status: { description: string };
  registeredAddress: {
    unitNo: string;
    levelNo: string;
    blkhseNo: string;
    streetName: string;
    postalCode: string;
  };
  ssic: {
    code: string;
    description: string;
    otherDescription: string;
  };
}

// Main component to display entities
const EntityView: React.FC<EntityProps> = ({ name, formerNames, uen, status, registeredAddress, ssic }) => {
  return (
    <Box
      maxW="lg"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      mb={5}
      boxShadow="lg"
      bg="white"
      borderColor="#7201a9"
      mt="30px"
    >
      <VStack align="start" spacing={4}>
        {/* Entity Name */}
        <Flex alignItems="center">
          <Icon as={FaBuilding} color="#7201a9" w={5} h={5} mr={2} />
          <Text fontSize="2xl" fontWeight="bold" color="#7201a9">
            {name}
          </Text>
        </Flex>

        {/* UEN */}
        <Text fontSize="sm" color="gray.600">
          <strong>UEN:</strong> {uen}
        </Text>

        {/* Former Names */}
        <Text fontSize="sm" color="gray.600">
          <strong>Former Names:</strong> {formerNames.join(', ')}
        </Text>

        <Divider />

        {/* Status */}
        <Text fontSize="sm" color="gray.600">
          <strong>Status:</strong> {status.description}
        </Text>

        {/* Registered Address */}
        <Flex alignItems="center">
          <Icon as={FaAddressCard} color="#7201a9" w={5} h={5} mr={2} />
          <Box>
            <Text fontSize="md" fontWeight="bold" color="#7201a9">
              Registered Address
            </Text>
            <Text fontSize="sm" color="gray.600">
              {registeredAddress.blkhseNo}, {registeredAddress.streetName}
            </Text>
            <Text fontSize="sm" color="gray.600">
              Level: {registeredAddress.levelNo}, Unit: {registeredAddress.unitNo}
            </Text>
            <Text fontSize="sm" color="gray.600">
              Postal Code: {registeredAddress.postalCode}
            </Text>
          </Box>
        </Flex>

        <Divider />

        {/* SSIC Details */}
        <Flex alignItems="center">
          <Icon as={FaCodeBranch} color="#7201a9" w={5} h={5} mr={2} />
          <Box>
            <Text fontSize="md" fontWeight="bold" color="#7201a9">
              SSIC Details
            </Text>
            <Text fontSize="sm" color="gray.600">
              Code: {ssic.code}
            </Text>
            <Text fontSize="sm" color="gray.600">
              Description: {ssic.description}
            </Text>
            <Text fontSize="sm" color="gray.600">
              Other Description: {ssic.otherDescription}
            </Text>
          </Box>
        </Flex>
      </VStack>
    </Box>
  );
};

// Sample data and rendering component
// const EntityList: React.FC<{ entities: EntityProps[] }> = ({ entities }) => {
//   return (
//     <Stack spacing={6} mx="auto" maxW="80%" p={5}>
//       {entities.map((entity, index) => (
//         <EntityCard key={index} {...entity} />
//       ))}
//     </Stack>
//   );
// };

export default EntityView;
