const PATHS = {
	HOME: "/",
	LOGIN: "/login",
	REGISTER: "/register",
	ONBOARDING: "/onboarding",
	DASHBOARD: "/dashboard",
	TECH_DASHBOARD: "/techDashboard",
	USER_MANAGER: "/userManager",
	ACRA_MANAGER: "/acra",
};

export { PATHS };
