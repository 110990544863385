import * as uuid from "uuid";
import axios, {
	HttpStatusCode,
	AxiosError,
	InternalAxiosRequestConfig,
	AxiosRequestHeaders,
} from "axios";
import NavigationUtil from "../utils/NavigationUtil";

const ApiService = axios.create({
	baseURL: NavigationUtil.getApiUrl(),
	withCredentials: true,
});

ApiService.interceptors.request.use(interceptRequest);
ApiService.interceptors.response.use(null, interceptResponseError);

let userEmail: string | null = null;

export const setUserEmail = (email: string) => {
	userEmail = email;
};

function interceptRequest(requestConfig: InternalAxiosRequestConfig) {
	requestConfig.headers = {
		...requestConfig.headers,
		"request-id": uuid.v4(),
		"user-email": userEmail,
	} as AxiosRequestHeaders & {
		"request-id": string;
		"user-email": string;
	};
	return requestConfig;
}

function interceptResponseError(error: AxiosError) {
	if (
		error.response?.status === HttpStatusCode.Unauthorized &&
		window.location.pathname !== "/login" &&
		window.location.pathname !== "/register"
	) {
		NavigationUtil.redirectToLogin();
	}
}

export default ApiService;
