import { config as faConfig } from "@fortawesome/fontawesome-svg-core";
import { createRoot } from "react-dom/client";
import App from "./App";

//this is a comment from test-push

faConfig.autoAddCss = false;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
