import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Center,
} from '@chakra-ui/react';
import { PRIMARY_COLOR, sampleUsers } from '../constant';
import Header from '../components/Header';

export interface User {
  email: string;
  number: string;
  userType: string;
}

const UserManager: React.FC = () => {
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userType, setUserType] = useState('staff');
  const [users, setUsers] = useState<User[]>(sampleUsers);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editedUser, setEditedUser] = useState<User | null>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [userToDisable, setUserToDisable] = useState<number | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const toast = useToast();

  // Add new user
  const handleAddUser = () => {
    setUserLoading(false);
    if (!email || !number) return;
    const newUser = { email, number, userType };
    setUsers([...users, newUser]);
    setEmail('');
    setNumber('');
    setUserType('staff');
  };

  // Open the modal to edit user
  const handleEditUser = (index: number) => {
    setEditIndex(index);
    setEditedUser(users[index]);
    onOpen();
  };

  // Save edited user
  const handleSaveUser = () => {
    if (editedUser && editIndex !== null) {
      const updatedUsers = [...users];
      updatedUsers[editIndex] = editedUser;
      setUsers(updatedUsers);
    }
    onClose();
  };

  // Handle disable user confirmation
  const handleDisableUser = (index: number) => {
    setUserToDisable(index);
    setIsConfirmOpen(true);
  };

  // Confirm disabling user
  const confirmDisableUser = () => {
    if (userToDisable !== null) {
      const updatedUsers = users.filter((_, idx) => idx !== userToDisable);
      setUsers(updatedUsers);
      setUserToDisable(null);
      toast({
        title: 'User disabled.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsConfirmOpen(false);
  };

  return (
    <Box>
      <Header/>
      <Center mt="100px">
        <Box borderWidth={1} borderRadius="md" p={4} mb={6}  w={"30%"} borderColor={PRIMARY_COLOR}>
            <Box fontSize="xl" fontWeight="bold" mb={4}>
            Add User
            </Box>
            <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter user email"
            />
            </FormControl>
            <FormControl mb={4}>
            <FormLabel>Mobile Number</FormLabel>
            <Input
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter mobile number"
            />
            </FormControl>
            <FormControl mb={4}>
            <FormLabel>User Type</FormLabel>
            <Select
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
            >
                {['staff', 'staff_admin', 'administrator', 'client', 'vendor'].map(
                (type) => (
                    <option key={type} value={type}>
                    {type}
                    </option>
                )
                )}
            </Select>
            </FormControl>
            <Button onClick={() => {setUserLoading(true); setTimeout(() => {handleAddUser()}, 2500); }} bg={PRIMARY_COLOR} color={"white"} isLoading={userLoading}>
            Add User
            </Button>
        </Box>
      </Center>
      
        <Center>
            <Box borderWidth={1} borderRadius="md" p={4} w={"90%"}>
                <Box fontSize="xl" fontWeight="bold" mb={4}>
                User List
                </Box>
                <Table variant="simple">
                <Thead>
                    <Tr>
                    <Th>Email</Th>
                    <Th>Number</Th>
                    <Th>User Type</Th>
                    <Th>Edit</Th>
                    <Th>Disable</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users.map((user, index) => (
                    <Tr key={index}>
                        <Td>{user.email}</Td>
                        <Td>{user.number}</Td>
                        <Td>{user.userType}</Td>
                        <Td>
                        <Button size="sm" onClick={() => handleEditUser(index)}>
                            Edit
                        </Button>
                        </Td>
                        <Td>
                        <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDisableUser(index)}
                        >
                            Disable
                        </Button>
                        </Td>
                    </Tr>
                    ))}
                </Tbody>
                </Table>
            </Box>
        </Center>
      

      {/* Edit User Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={editedUser?.email || ''}
                onChange={(e) =>
                  setEditedUser({ ...editedUser!, email: e.target.value })
                }
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Mobile Number</FormLabel>
              <Input
                type="text"
                value={editedUser?.number || ''}
                onChange={(e) =>
                  setEditedUser({ ...editedUser!, number: e.target.value })
                }
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>User Type</FormLabel>
              <Select
                value={editedUser?.userType || ''}
                onChange={(e) =>
                  setEditedUser({ ...editedUser!, userType: e.target.value })
                }
              >
                {['staff', 'staff_admin', 'administrator', 'client', 'vendor'].map(
                  (type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg={PRIMARY_COLOR} mr={3} onClick={handleSaveUser}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Disable User</AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to disable this user?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDisableUser}
                ml={3}
              >
                Disable
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default UserManager;
