import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
	Input,
	Flex,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Button,
	Box,
	Checkbox,
	Text,
} from "@chakra-ui/react";
import AttachmentInput from "../components/CustomInputs/AttachmentInput";
import {
	Field,
	FieldArray,
	FieldProps,
	Form,
	Formik,
	FormikHelpers,
	FormikProps,
} from "formik";
import { useState, ChangeEvent } from "react";
import {
	DirectorInputs,
	OnboardingFormInputs,
	OnboardingInputs,
	StakeholderInputs,
} from "../types/Onboarding.type";

const initialInputs: OnboardingInputs = {
	email: "",
	entityName: "",
	firstName: "",
	lastName: "",
	nric: "",
	uen: "",
	companyAddress: "",
	dateOfIncorporation: new Date(),
	stakeholders: [],
	directors: [
		{ firstName: "", lastName: "", nominee: false, idAttachment: "" },
	],
};

const OnboardingPage = () => {
	const handleSubmit = (
		values: OnboardingInputs,
		actions: FormikHelpers<OnboardingInputs>
	) => {
		try {
			console.log(values, "Send POST req");
			actions.resetForm({
				values: initialInputs,
				errors: {},
				touched: {},
			});
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Flex justifyContent="center">
			<Formik
				initialValues={initialInputs}
				// validateOnMount
				enableReinitialize
				validate={(values) => {
					let error: any = {};
					if (!values.email) {
						error.email = "Email is required";
					}
					return error;
				}}
				onSubmit={handleSubmit}
			>
				{({
					touched,
					errors,
					isSubmitting,
					isValid,
					values,
				}: FormikProps<OnboardingInputs>) => (
					<Form>
						<Flex
							gap="5%"
							w="70vw"
							//    flexWrap="wrap"
							//    bgColor="yellow"
							direction="column"
						>
							<Field key="email" name="email">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl
											w="100%"
											isRequired
											mb="2rem"
											isInvalid={!!errors["email"]}
										>
											<FormLabel>Email address</FormLabel>
											<Input
												type="email"
												name="email"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
											<FormHelperText>
												We'll never share your email.
											</FormHelperText>
											<FormErrorMessage wordBreak="break-word">
												{errors["email"]}
											</FormErrorMessage>
										</FormControl>
									);
								}}
							</Field>
							<Field key="entityName" name="entityName">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl w="100%" isRequired mb="2rem">
											<FormLabel>Entity name</FormLabel>
											<Input
												name="entityName"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										</FormControl>
									);
								}}
							</Field>
							<Field key="nric" name="nric">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl w="100%" isRequired mb="2rem">
											<FormLabel>NRIC</FormLabel>
											<Input
												name="nric"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										</FormControl>
									);
								}}
							</Field>
							<Field key="firstName" name="firstName">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl w="100%" isRequired mb="2rem">
											<FormLabel>First Name</FormLabel>
											<Input
												name="firstName"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										</FormControl>
									);
								}}
							</Field>
							<Field key="lastName" name="lastName">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl w="100%" isRequired mb="2rem">
											<FormLabel>Last Name</FormLabel>
											<Input
												name="lastName"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										</FormControl>
									);
								}}
							</Field>
							<Field key="companyAddress" name="companyAddress">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl w="100%" isRequired mb="2rem">
											<FormLabel>Company Address</FormLabel>
											<Input
												name="companyAddress"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										</FormControl>
									);
								}}
							</Field>
							<Field key="dateOfIncorporation" name="dateOfIncorporation">
								{({ field }: FieldProps) => {
									const { value, onChange, onBlur } = field;
									return (
										<FormControl w="100%" isRequired mb="2rem">
											<FormLabel>Date of Incorporation</FormLabel>
											<Input
												type="date"
												name="dateOfIncorporation"
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										</FormControl>
									);
								}}
							</Field>
							<Flex
								m="16px 0"
								data-testid="FieldContainer"
								direction="column"
								w="100%"
								border="1px dotted grey"
								p={5}
								borderRadius="md"
							>
								<Box
									display="inline-block"
									mb="8px"
									fontWeight="1000"
									fontSize="md"
									color="#009691"
									//  textTransform="uppercase"
								>
									Director(s)
								</Box>

								<FieldArray name="directors">
									{({ remove, push }) => (
										<Flex direction="column">
											{values.directors!.map((director, index) => (
												<Flex
													w="100%"
													padding="12px 16px"
													borderRadius="8px"
													backgroundColor="rgba(38, 106, 113, 0.3)"
													direction="column"
													mb="1rem"
												>
													<Field
														key={index}
														name={`directors[${index}].firstName`}
													>
														{({ field }: FieldProps) => {
															const { value, onChange, onBlur } = field;
															return (
																<FormControl w="100%" isRequired mb="2rem">
																	<FormLabel>First Name</FormLabel>
																	<Input
																		name={`directors[${index}].firstName`}
																		value={value}
																		onChange={onChange}
																		onBlur={onBlur}
																	/>
																</FormControl>
															);
														}}
													</Field>
													<Field
														key={index}
														name={`directors[${index}].lastName`}
													>
														{({ field }: FieldProps) => {
															const { value, onChange, onBlur } = field;
															return (
																<FormControl w="100%" isRequired mb="2rem">
																	<FormLabel>Last Name</FormLabel>
																	<Input
																		name={`directors[${index}].lastName`}
																		value={value}
																		onChange={onChange}
																		onBlur={onBlur}
																	/>
																</FormControl>
															);
														}}
													</Field>
													<Field
														type="checkbox"
														name={`directors[${index}].nominee`}
													>
														{({ field }: FieldProps) => {
															const { value, onChange, onBlur } = field;
															return (
																<Checkbox
																	name={`directors[${index}].nominee`}
																	colorScheme="primary"
																	isChecked={value}
																	onChange={onChange}
																	mb="2rem"
																>
																	<Text fontSize="sm">Nominee director</Text>
																</Checkbox>
															);
														}}
													</Field>
													<Field
														key={index}
														name={`directors[${index}].idAttachment`}
													>
														{({ field }: FieldProps) => {
															const { value, onChange, onBlur } = field;
															return (
																<FormControl w="100%" isRequired mb="2rem">
																	<FormLabel>
																		Please include a picture of director's NRIC
																		or Passport
																	</FormLabel>
																	<AttachmentInput
																		name={`directors[${index}].idAttachment`}
																		value={value}
																		onChange={onChange}
																	/>
																</FormControl>
															);
														}}
													</Field>
													{director.nominee && (
														<Field
															key={index}
															name={`directors[${index}].nominationAttachment`}
														>
															{({ field }: FieldProps) => {
																const { value, onChange, onBlur } = field;
																return (
																	<FormControl w="100%" isRequired mb="2rem">
																		<FormLabel>
																			Please include document of nomination
																		</FormLabel>
																		<AttachmentInput
																			name={`directors[${index}].nominationAttachment`}
																			value={value}
																			onChange={onChange}
																		/>
																	</FormControl>
																);
															}}
														</Field>
													)}
													<Flex justifyContent="end">
														<Button
															variant="ghost"
															colorScheme="red"
															fontSize="sm"
															_hover={{ color: "red.700" }}
															onClick={() => remove(index)}
														>
															X Remove director
														</Button>
													</Flex>
												</Flex>
											))}
											<Flex
												w="fit-content"
												p="20px"
												alignItems="center"
												justifyContent="center"
												backgroundColor="primary"
												border="2px solid #bfbfbf"
												borderStyle="dashed"
												borderRadius="8px"
											>
												<Button
													// isDisabled={isClosed}
													onClick={() =>
														push({
															firstName: "",
															lastName: "",
															nominee: false,
															idAttachment: "",
														})
													}
												>
													+ Add director
												</Button>
											</Flex>
										</Flex>
									)}
								</FieldArray>
							</Flex>

							<Button
								isDisabled={!isValid}
								isLoading={isSubmitting}
								type="submit"
							>
								Submit
							</Button>
						</Flex>
					</Form>
				)}
			</Formik>
		</Flex>
	);
};

export default OnboardingPage;
