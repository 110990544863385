import {  TicketStatus, User } from "./Ticket.type";
import {  SeqTicket, TicketStream, TicketType, EntityObject, CurrentAction } from "./SeqTicket.type";

const sampleUsers = [
    new User("U001", "Alice Johnson", "Alice Johnson"),
    new User("U002", "Bob Smith", "Bob Smith"),
    new User("U003", "Clara Williams", "Clara Williams"),
];

const sampleTicketList: SeqTicket[] = [
    new SeqTicket(
        "TKT-1001",
        TicketStatus.OPEN,
        new Date("2024-10-01T09:00:00Z"),
        null,
        "Issue with entity registration process",
        sampleUsers[0],
        new EntityObject("C001", "201234567A", "Acme Corp"),
        "WO-001",
        TicketType.EXTERNAL,
        TicketStream.CORP_SEC,
        sampleUsers[1],
        sampleUsers[2],
        new Date("2024-12-01T17:00:00Z"),
        [
            new CurrentAction(
                "CA-001",
                "Verify entity registration details",
                ["file1.pdf", "file2.pdf"],
                new Date("2024-10-10T12:00:00Z")
            )
        ],
        "Initial issue with data mismatch in registration."
    ),
    new SeqTicket(
        "TKT-1002",
        TicketStatus.IN_PROGRESS,
        new Date("2024-09-28T11:30:00Z"),
        null,
        "Auditing error found in Q3 financials",
        sampleUsers[1],
        new EntityObject("C002", "202345678B", "Globex Inc"),
        "WO-002",
        TicketType.INTERNAL,
        TicketStream.ACCOUNTING_AUDIT,
        sampleUsers[0],
        sampleUsers[1],
        new Date("2024-11-15T18:00:00Z"),
        [
            new CurrentAction(
                "CA-002",
                "Review financial records and correct errors",
                ["financials-q3.pdf"],
                new Date("2024-10-05T16:00:00Z")
            ),
            new CurrentAction(
                "CA-003",
                "Prepare revised audit report",
                [],
                new Date("2024-10-12T10:00:00Z")
            )
        ],
        "Audit found errors in recent reports."
    ),
    new SeqTicket(
        "TKT-1003",
        TicketStatus.CLOSED,
        new Date("2024-08-15T08:45:00Z"),
        new Date("2024-09-01T17:00:00Z"),
        "Request for client data migration to new system",
        sampleUsers[2],
        new EntityObject("C003", "203456789C", "Wayne Enterprises"),
        "WO-003",
        TicketType.EXTERNAL,
        TicketStream.CORP_SEC,
        sampleUsers[2],
        sampleUsers[0],
        new Date("2024-08-31T18:00:00Z"),
        [
            new CurrentAction(
                "CA-004",
                "Initiate data migration process",
                ["migration-plan.pdf"],
                new Date("2024-08-20T09:00:00Z")
            )
        ],
        "Data migration completed successfully."
    ),
    new SeqTicket(
        "TKT-1004",
        TicketStatus.PENDING,
        new Date("2024-09-10T14:00:00Z"),
        null,
        "User access permission setup for new employee",
        sampleUsers[0],
        new EntityObject("C004", "204567890D", "Initech"),
        "WO-004",
        TicketType.INTERNAL,
        TicketStream.CORP_SEC,
        sampleUsers[1],
        sampleUsers[1],
        new Date("2024-10-20T18:00:00Z"),
        [
            new CurrentAction(
                "CA-005",
                "Verify access levels required for new employee",
                [],
                new Date("2024-09-15T14:00:00Z")
            ),
            new CurrentAction(
                "CA-006",
                "Set up permissions in system",
                ["access-levels.pdf"],
                new Date("2024-09-18T12:00:00Z")
            )
        ],
        "Pending verification of permissions."
    ),
    new SeqTicket(
        "TKT-1005",
        TicketStatus.DONE,
        new Date("2024-10-01T09:30:00Z"),
        new Date("2024-10-05T14:00:00Z"),
        "Annual report submission",
        sampleUsers[1],
        new EntityObject("C005", "205678901E", "Stark Industries"),
        "WO-005",
        TicketType.EXTERNAL,
        TicketStream.ACCOUNTING_AUDIT,
        sampleUsers[2],
        sampleUsers[2],
        new Date("2024-11-30T17:00:00Z"),
        [
            new CurrentAction(
                "CA-007",
                "Review financial data for annual report",
                ["annual-report-draft.pdf"],
                new Date("2024-10-02T10:00:00Z")
            )
        ],
        "Annual report approved and submitted."
    )
];

export default sampleTicketList;
