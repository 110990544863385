import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			// refetchInterval: 15000,
		},
	},
});

export default queryClient;
