import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Progress,
  useToast,
  InputProps,
} from '@chakra-ui/react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { PRIMARY_COLOR } from '../constant';

interface FileProgress {
  [key: string]: number;
}

const acceptedFormats = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

interface FileUploaderProps {
  width: string;
  height: string,
  onFileSelect: Function
}
const FileUploader: React.FC<FileUploaderProps> = (props:FileUploaderProps) => {
  const {width, height, onFileSelect} = props;
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<FileProgress>({});
  const toast = useToast();

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const validFiles:Array<File> = []; 
    //acceptedFiles.filter((file) => acceptedFormats[file.type]);

    if (validFiles.length !== acceptedFiles.length || rejectedFiles.length > 0) {
      toast({
        title: 'Invalid file type.',
        description: 'Only PDF and DOC files are allowed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone();

  const handleUpload = (file: File) => {
    const reader = new FileReader();

    reader.onloadstart = () => {
      setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
    };

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded * 100) / event.total);
        setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
      }
    };

    reader.onloadend = () => {
      setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
    };

    reader.readAsDataURL(file);
  };

  const removeFile = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setUploadProgress((prevProgress) => {
      const { [fileName]: _, ...rest } = prevProgress;
      return rest;
    });
  };

  return (
    <VStack spacing={4} align="stretch" w={width} h={height}>
      <Box
        h={height}
        p={5}
        borderWidth={2}
        borderRadius="lg"
        borderStyle="dashed"
        borderColor={isDragActive ? 'blue.500' : PRIMARY_COLOR}
        bg={isDragActive ? 'blue.50' : 'transparent'}
        cursor="pointer"
        {...getRootProps()}
      >
        {/* <Input {...getInputProps()} /> */}
        {isDragActive ? (
          <Text>Drop the files here ...</Text>
        ) : (
          <Text>Drag & drop some files here, or click to select files (PDF, DOC)</Text>
        )}
      </Box>

      {files.length > 0 && (
        <VStack align="stretch" spacing={3}>
          {files.map((file) => (
            <Box key={file.name}>
              <HStack>
                <Tag size="lg" variant="solid" colorScheme="blue">
                  <TagLabel>{file.name}</TagLabel>
                  <TagCloseButton onClick={() => removeFile(file.name)} />
                </Tag>
              </HStack>
              <Progress
                value={uploadProgress[file.name] || 0}
                size="sm"
                colorScheme="green"
                mt={2}
                isIndeterminate={uploadProgress[file.name] === undefined}
              />
            </Box>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default FileUploader;
