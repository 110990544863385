import React, { useState } from 'react';
import { Box, Flex, Text, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR, PRIMARY_FONT } from '../constant';
import { FiMoreVertical } from 'react-icons/fi';
import { PATHS } from '../constants/Constants';

export default function Header(props: any) {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);

    const handleLogout = () => {
        setIsLoading(false);
        navigate('/'); 
    };

    const handleUserManagement = () => {
        navigate(PATHS.USER_MANAGER);
    };

    const handleDashboard = () => {
        navigate(PATHS.DASHBOARD);
    };

    const handleTickets = () => {
        navigate(PATHS.TECH_DASHBOARD);
    };

    const handleAcra = () => {
        navigate(PATHS.ACRA_MANAGER);
    };

    return (
        <Box
            bg="white"
            position="fixed"
            top={0}
            width="100%"
            px={4}
            py={2}
            boxShadow="md"
            zIndex="1000"
        >
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <Box ml="10px">
                    <Text
                        as="h1"
                        fontSize="30px"
                        color={PRIMARY_COLOR}
                        fontFamily={PRIMARY_FONT}
                        onClick={() => { navigate("/") }}
                        _hover={{ cursor: "pointer" }}
                    >
                        sequence
                    </Text>
                </Box>
                {/* <Button
                    colorScheme="purple"
                    variant="outline"
                    onClick={() => { setIsLoading(true); setTimeout(() => {handleLogout()}, 1500)}}
                    size="sm"
                    mr="20px"
                    isLoading={isLoading}>
                    Log Out
                </Button> */}
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<FiMoreVertical />}
                        variant="ghost"
                        mr="20px" // Add margin to the right
                    />
                    <MenuList>
                        <MenuItem onClick={() => {handleDashboard()}}>Dashboard</MenuItem>
                        <MenuItem onClick={() => {handleTickets()}}>Technical dashboard</MenuItem>
                        <MenuItem onClick={() => {handleUserManagement()}}>User Management</MenuItem>
                        <MenuItem onClick={() => {handleAcra()}}>Entity Management</MenuItem>
                        <MenuItem onClick={() => {setIsLoading(true); setTimeout(() => {handleLogout()}, 1500)}}>Log Out</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Box>
    );
}
