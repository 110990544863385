import React, { useEffect, useState } from 'react';
import { RiShieldCheckLine } from "react-icons/ri";
import {
    Center,
    InputGroup,
    InputRightElement,
    Text,
    Flex,
    Box,
    Icon,
    Button,
    Input,
    Heading,
    Card,
    useToast
} from '@chakra-ui/react';
import SwipeableViews from 'react-swipeable-views';
import { CheckIcon } from '@chakra-ui/icons';
import { EMPTY, LOGIN, SUBMIT } from '../strings/en_strings';
import { isValidEmail, storeUserData } from '../utils/utilities';
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR } from '../constant';
import { onLogin } from '../serviceManager';
import { LoginBody } from '../types/User.type';
import { LoginResponse } from '../types/Login.type';


function Login(props:any) {
    const navigate = useNavigate();
    const toast = useToast();

    const [ isLoading, setIsLoading ] = useState(false);
    
    const [ viewType, setViewType ] = useState(1);

    const [ email, setEmail ] = useState(EMPTY);
    const [ isEmailCorrect, setIsEmailCorrect ] = useState(false);
    const [ showEmailAlert, setShowEmailAlert ] = useState(false);

    const [ passwordEntry, setPasswordEntry ] = useState(EMPTY);
    const [ showPasswordAlert, setShowPasswordAlert ] = useState(false);

    useEffect(() => {
        setShowEmailAlert(false);
        if(email){
            setIsEmailCorrect(isValidEmail(email));
        }else{
            setIsEmailCorrect(false);
        }
    },[email]);

    useEffect(() => {
        setShowPasswordAlert(false);
    },[passwordEntry]);

    const onLoginSubmit = () => {
        if(isEmailCorrect && passwordEntry){
            setIsLoading(true);
            
            const body:LoginBody = {
                email:email,
                number:EMPTY,
                password:passwordEntry
            }
            
            if(email?.toLowerCase() === "ivan@sequence.sg" && passwordEntry === "$eQuence@$Y4202"){
                setTimeout(() => {
                    let loginRes = {
                        token:"",
                        message:"Login successfully",
                        user:{
                            email: "ivan@sequence.sg",
                            number: "",
                            inActiveType: "",
                            groupName:"administrator",
                            permission: {
                                permissionName: "all",
                                isAll: true,
                                isWrite: true,
                                isRead: true,
                            }
                        }
                    }
                    onLoginSuccess(loginRes);
                }, 5000);
            }else{
                setTimeout(() => {
                    onLoginFailure("Incorrect email or password");
                }, 5000);
            }
            //onLogin(onLoginSuccess,onLoginFailure,body);

        }else if(!isEmailCorrect && !passwordEntry){
            setShowEmailAlert(true);
            setShowPasswordAlert(true);
        }else if(!isEmailCorrect){
            setShowEmailAlert(true);
        }else{
            setShowPasswordAlert(true);
        }
    }

    const onResetPasswordSubmit = () => {
        if(isEmailCorrect){
            setIsLoading(true);
            
            const body:LoginBody = {
                email:email,
                number:EMPTY,
                password:EMPTY
            }
            
            //onLogin(onLoginSuccess,onLoginFailure,body);

        }else{
            setShowEmailAlert(true);
        }
    }

    const onLoginSuccess = (loginRes: LoginResponse) => {
        setIsLoading(false);
        toast({
            description: loginRes.message,
            status: "success",
            isClosable: true,
        });
        storeUserData(loginRes);
        resetPasswordValues();
        navigate("/dashboard");
    }

    const onLoginFailure = (message: string) => {
        setIsLoading(false);
        toast({
            description: message,
            status: "error",
            isClosable: true,
        });
    }

    const resetPasswordValues = () => {
      setPasswordEntry(EMPTY);
      setEmail(EMPTY)
    }

    return (
      <>
      <Center mt="275px">
        <Box w="450px">
            <Card boxShadow="0 0 20px rgba(0, 0, 0, 0.25)" p="30px">
            <SwipeableViews axis='x' index={viewType}>
                <>
                    <Center mb={"20px"}> 
                        <Heading>
                            <Box display="flex" alignItems="center">
                            <Text fontSize="lg" mr="5px" color={PRIMARY_COLOR}>Reset Password</Text>
                            <Icon as={RiShieldCheckLine} color={PRIMARY_COLOR}/>
                            </Box>
                        </Heading>
                    </Center>
                    <InputGroup mb="10px">
                        <Input placeholder='Email*' focusBorderColor={PRIMARY_COLOR} bgColor={"white"} onChange={(event) => {setEmail(event?.target?.value)}} value={email} isRequired isDisabled={isLoading}/>
                        {isEmailCorrect?<InputRightElement>
                            <CheckIcon color={PRIMARY_COLOR}/>
                        </InputRightElement>:null}
                    </InputGroup>
                    <Center>
                        <Flex w="100%" direction="column" >
                            <Button mb="10px" w="100%" bg={PRIMARY_COLOR} _hover={{bg:PRIMARY_COLOR}} color="white" isLoading={isLoading} loadingText='Checking user...' onClick={() => {onResetPasswordSubmit()}}>{SUBMIT}</Button>
                            {/* <Button  mb="10px" w="100%" bg="white" color={PRIMARY_COLOR} border="1px" borderColor={PRIMARY_COLOR} onClick={() => {}}>SSO</Button> */}
                            <Button  w="100%" bg="white" color={PRIMARY_COLOR} onClick={() => {setViewType(1)}}>Login ?</Button>
                            {(showEmailAlert)?<Text color={PRIMARY_COLOR}>Please enter a valid email</Text>:null}
                        </Flex>
                    </Center>
                </>
                <>
                    <Center mb={"20px"}> 
                        <Heading>
                            <Box display="flex" alignItems="center">
                            <Text fontSize="lg" mr="5px" color={PRIMARY_COLOR}>Log In to Sequence</Text>
                            <Icon as={RiShieldCheckLine} color={PRIMARY_COLOR}/>
                            </Box>
                        </Heading>
                    </Center>
                    <InputGroup mb="10px">
                        <Input placeholder='Email*' focusBorderColor={PRIMARY_COLOR} bgColor={"white"} onChange={(event) => {setEmail(event?.target?.value)}} value={email} isRequired isDisabled={isLoading}/>
                        {isEmailCorrect?<InputRightElement>
                            <CheckIcon color={PRIMARY_COLOR}/>
                        </InputRightElement>:null}
                    </InputGroup>
                    <InputGroup mb="10px">
                        <Input placeholder='Password*' focusBorderColor={PRIMARY_COLOR} bgColor={"white"} onChange={(event) => {setPasswordEntry(event?.target?.value)}} value={passwordEntry} type="password" isDisabled={isLoading}/>
                    </InputGroup>
                    <Center>
                        <Flex w="100%" direction="column" >
                            <Button mb="10px" w="100%" bg={PRIMARY_COLOR} _hover={{bg:PRIMARY_COLOR}} color="white" isLoading={isLoading} loadingText='Logging in...' onClick={() => {onLoginSubmit()}}>{LOGIN}</Button>
                            {/* <Button  mb="10px" w="100%" bg="white" color={PRIMARY_COLOR} border="1px" borderColor={PRIMARY_COLOR} onClick={() => {}}>SSO</Button> */}
                            <Button  w="100%" bg="white" color={PRIMARY_COLOR} onClick={() => {setViewType(0)}}>Forgot Password ?</Button>
                            {(showEmailAlert)?<Text color={PRIMARY_COLOR}>Please enter a valid email</Text>:null}
                            {(showPasswordAlert)?<Text color={PRIMARY_COLOR}>Please enter a valid password</Text>:null}
                        </Flex>
                    </Center>
                </>
            </SwipeableViews>
            </Card>
        </Box>
      </Center>  
      </>
    )
  }

export default Login;