import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../components/Header';
import TicketView from '../components/TicketView';
import TicketModal from '../components/TicketModal';
import TicketTable from '../components/TicketTable';
import ApiCallerComponent from '../components/ApiCallerComponent';

export default function Dashboard() {
  const [isEditTicketOpen, setIsEditTicketOpen] = useState(false);
  return (
    <Box>
        <Header/>
        {/* <TicketView handleCreateTicket={() => {setIsEditTicketOpen(true)}}/> */}
        <TicketTable handleCreateTicket={() => {setIsEditTicketOpen(true)}}/>
        <TicketModal w="50%" isEditTicketOpen={isEditTicketOpen}
              handleCreateTicket={() => {setIsEditTicketOpen(true)}}
              setIsEditTicketOpen={setIsEditTicketOpen}/>
    </Box>
  )
}
