export enum TicketStatus {
    OPEN = 'Open',
    IN_PROGRESS = 'In Progress',
    CLOSED = 'Closed',
    DONE = 'Done',
    PENDING = "Pending",
    ON_HOLD = "On Hold"
}

export class User {
    constructor(
        public userId: string,
        public userEmail: string,
        public userName: string
    ) {}
}

export class Ticket {
    constructor(
        public ticketId: string,
        public status: TicketStatus,
        public ticketRaisedDateTime: Date,
        public ticketClosedDateTime: Date | null,
        public ticketDescription: string,
        public ticketCreatedBy: User
    ) {}
}
