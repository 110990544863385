import React, { useEffect, useState } from 'react';
import { RiShieldCheckLine } from "react-icons/ri";
import { PRIMARY_COLOR, PRIMARY_FONT } from '../constant';
import { EMPTY, LOGIN, NEXT, SUBMIT } from '../strings/en_strings';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  InputGroup,
  Flex,
  Box,
  Text,
  Icon,
  Button,
  Textarea,
  Input,
  Select,
  useToast,
  InputRightElement,
  HStack,
  VStack,
  Heading,
  Stack
} from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { CheckIcon } from '@chakra-ui/icons';
import FileUploader from './FileUploader';
import SwipeableViews from 'react-swipeable-views';
import { validate } from 'uuid';
import SeqDropDown, { SeqDropDownOptions } from './SeqDropDown';
import { getUserData } from '../utils/utilities';
import { useNavigate } from 'react-router-dom';
import { EntityObject } from '../types/Ticketing/SeqTicket.type';
import TicketEntityForm from './Ticketing/TicketEntityForm';
import { User } from '../types/Ticketing/Ticket.type';
import CurrentActionForm from './Ticketing/CurrentActionForm';


function TicketModal(props:any) {
  const toast = useToast();
  const navigate = useNavigate();
  const { 
    isEditTicketOpen,
    handleCreateTicket,
    setIsEditTicketOpen,
  } = props;

  const [ userDetails, setUserDetails ] = useState({});
  const [ serviceType, setServiceType] = useState("CorpSec");
  const [ description, setDescription ] = useState(EMPTY);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    const user = getUserData();
    if(user){
      setUserDetails(user);
    }else{
      navigate("/login");
    }
  },[]);

  const services = [
    {label:"Corp Secretarial", value:"CorpSec"},
    {label:"Accounting & Auditing", value:"AccountsAndAudit"}
  ];

  const users:Array<User> = [
    {userEmail:"Ivan", userId:"001", userName:"Ivan"},
    {userEmail:"Andrew", userId:"002", userName:"Andrew"},
    {userEmail:"Emma", userId:"003", userName:"Emma"}
  ];

  const entities = [
    {
      "clientId": "C00000001",
      "entityName": "SEQUENCE PTE. LTD.",
      "UEN": "202346906G"
    },
    {
      "clientId": "C00000002",
      "entityName": "SEQUENCE SG BIZCO PTE. LTD.",
      "UEN": "202346260R"
    },
    {
      "clientId": "C00000003",
      "entityName": "NAIX PTE. LTD.",
      "UEN": "201915339R"
    },
    {
      "clientId": "C00000004",
      "entityName": "CHESTNUT CONSULTING PTE. LTD.",
      "UEN": "202414547G"
    },
    {
      "clientId": "C00000005",
      "entityName": "CAO YU JING",
      "UEN": "TBD (E67876792 )"
    },
    {
      "clientId": "C00000006",
      "entityName": "GROUNDWORKS SG PTE. LTD.",
      "UEN": "202036341G"
    },
    {
      "clientId": "C00000007",
      "entityName": "GUSH INTERNATIONAL PTE. LTD.",
      "UEN": "202036327G"
    },
    {
      "clientId": "C00000008",
      "entityName": "GUSH PTE. LTD. (F.K.A. LIVINGWALL PTE. LTD.)",
      "UEN": "201713542C"
    },
    {
      "clientId": "C00000009",
      "entityName": "PROLIFIC BUILDERS PTE. LTD.",
      "UEN": "202324399W"
    },
    {
      "clientId": "C00000010",
      "entityName": "LITTLE SKITTLES INTERNATIONAL PRESCHOOL PTE. LTD.",
      "UEN": "201730225M"
    },
    {
      "clientId": "C00000011",
      "entityName": "FLOWERS CITY PTE. LTD.",
      "UEN": "200921442N"
    },
    {
      "clientId": "C00000012",
      "entityName": "SINGAPORE ZHONG XIN BUSINESS CONSULTING PTE. LTD. (F.K.A SINGAPORE WAN XIN BUSINESS CONSULTING PTE. LTD)",
      "UEN": "TBI"
    },
    {
      "clientId": "C00000013",
      "entityName": "NURTURED NEST BUKIT TIMAH PTE. LTD.",
      "UEN": "201414634K"
    },
    {
      "clientId": "C00000014",
      "entityName": "KINGSTON MEDICAL CLINIC PTE. LTD",
      "UEN": "201726945Z"
    },
    {
      "clientId": "C00000015",
      "entityName": "PETALS AND BLOOM PTE. LTD.",
      "UEN": "TBI"
    },
    {
      "clientId": "C00000016",
      "entityName": "CREDIT CLAIM BUREAU PTE. LTD.",
      "UEN": "201906963N"
    },
    {
      "clientId": "Pipeline ID",
      "entityName": "entityName",
      "UEN": "UEN"
    },
    {
      "clientId": "Z00000001",
      "entityName": "SMILEFOKUS PRIVATE LIMITED",
      "UEN": "201529273R",
    },
    {
      "clientId": "Z00000002",
      "entityName": "MAPLEBEAR TINY LEARNERS PTE. LTD.",
      "UEN": "202144116D"
    },
    {
      "clientId": "Z00000003",
      "entityName": "SUNNY SIDE UP CREATIVE PTE. LTD.",
      "UEN": "201702544H"
    },
    {
      "clientId": "Z00000004",
      "entityName": "CAS & CHER INTERIORS PTE. LTD.",
      "UEN": "202329177D"
    },
    {
      "clientId": "Z00000005",
      "entityName": "DEVICETOTAL SG PTE. LTD.",
      "UEN": "202317133W",
    }
  ];

  const handleNext = () => {
    setStepIndex((prevIndex) => Math.min(prevIndex + 1, 4));
  };

  const handleBack = () => {
    setStepIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const getEntityDropDownValues = (values:Array<EntityObject>):Array<SeqDropDownOptions> => {
    const newValues:Array<SeqDropDownOptions> = [];
    for(let i = 0; i < values.length; i++){
      newValues.push({label:values[i].entityName + " - " + values[i].UEN,value:values[i].UEN});
    }
    return newValues;
  }

  const getUserDropDownValues = (values:Array<User>):Array<SeqDropDownOptions> => {
    const newValues:Array<SeqDropDownOptions> = [];
    for(let i = 0; i < values.length; i++){
      newValues.push({label:values[i].userName,value:values[i].userId});
    }
    return newValues;
  }
    

    return (
      <>
        <Modal isOpen={isEditTicketOpen} onClose={() =>{setIsEditTicketOpen(false)}} isCentered>
          <ModalOverlay />
          <ModalContent fontFamily={PRIMARY_FONT} maxWidth="1200px">
            <ModalHeader>
              <Box display="flex" alignItems="center">
                <Text fontSize="lg" mr="5px" color={PRIMARY_COLOR}>Create Ticket</Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton onClick={() => {setIsEditTicketOpen(false)}}/>
            <ModalBody>
              <Tabs index={stepIndex} onChange={setStepIndex} isFitted variant='enclosed'>
                <TabList>
                  <Tab>Ticket Information</Tab>
                  <Tab>Current Action</Tab>
                </TabList>
              </Tabs>
              <SwipeableViews index={stepIndex} onChangeIndex={setStepIndex}>
                <Box>
                  <TicketEntityForm users={getUserDropDownValues(users)} entities={getEntityDropDownValues(entities)} onSubmit={()=>{}}/>
                </Box>
                <Box>
                  <CurrentActionForm users={getUserDropDownValues(users)}  onSubmit={()=>{}}/>
                </Box>
                {/* <Box>
                  <HStack>
                    <VStack w="50%" mr="20px">
                      <SeqDropDown options={getEntityDropDownValues(entities)} placeholderText={"Select Entity"} onSelect={(option:SeqDropDownOptions) => {}}/>
                      <Flex mt="10px" w="100%">
                        <SeqDropDown options={services} placeholderText={"Select Service Type"} onSelect={(option:SeqDropDownOptions) => {}}/>
                      </Flex>
                      <Flex mt="10px" w="100%">
                        <SeqDropDown options={users} placeholderText={"Assign User"} onSelect={(option:SeqDropDownOptions) => {}}/>
                      </Flex>
                      <InputGroup my="10px">
                          <Textarea h="200px" placeholder='Add ticket details*' focusBorderColor={PRIMARY_COLOR} onChange={(e) => {setDescription(e.target.value)}} value={description}/>
                      </InputGroup>
                    </VStack>
                    <Flex w="50%" mr="20px">
                      <FileUploader width={"100%"} height={"350px"} onFileSelect={()=>{}}/>
                    </Flex>
                  </HStack>
                </Box> */}
              </SwipeableViews>
            </ModalBody>
            <Center>
              <ModalFooter  w="100%">
                <Stack direction="row" justify="space-between">
                  <Button onClick={handleBack} isDisabled={stepIndex === 0}>
                    Back
                  </Button>
                  <Button onClick={handleNext} isDisabled={stepIndex === 1}>
                    Next
                  </Button>
                </Stack>
                <Flex w="100%" justifyContent="flex-end">
                  <Button mb="10px" w="150px" mr="10px"
                          border={`1px solid ${PRIMARY_COLOR}`}
                          bg={"white"} 
                          color={PRIMARY_COLOR} 
                          _hover={{color:PRIMARY_COLOR, bg: "#ffffff"}}
                          loadingText='Submitting'
                          onClick={() => {setIsEditTicketOpen(false)}}>
                              {"Cancel"}
                  </Button>
                  <Button mb="10px" w="150px" 
                          bg={PRIMARY_COLOR} 
                          color="white"
                          isDisabled={stepIndex === 0}
                          _hover={{color:"#ffffff", bg: PRIMARY_COLOR}}
                          loadingText='Submitting'
                          onClick={() => {handleCreateTicket()}}>
                              {"Create Ticket"}
                  </Button>
                </Flex>
              </ModalFooter>
            </Center>
          </ModalContent>
        </Modal>
      </>
    )
  }

export default TicketModal;