import React, { useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Progress,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import SeqDropDown, { SeqDropDownOptions } from "../SeqDropDown";
import { PRIMARY_COLOR } from "../../constant";
import FileUploader from "../FileUploader";

interface User {
  userId: string;
  userName: string;
}

interface CurrentActionProps {
  users:  SeqDropDownOptions[];
  onSubmit: (data: any) => void;
}

const CurrentActionForm: React.FC<CurrentActionProps> = ({ users, onSubmit }) => {
  const [currentActionOwner, setCurrentActionOwner] = useState("");
  const [currentActionDescription, setCurrentActionDescription] = useState("");
  const [currentActionDeadline, setCurrentActionDeadline] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const handleFocus = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker(); // Use this method to open the date picker on focus.
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setSelectedFiles(fileList);
      setUploadProgress(new Array(fileList.length).fill(0));

      // Simulate file upload progress
      fileList.forEach((file, index) => {
        const progress = Math.floor(Math.random() * 100) + 1; // Random upload progress
        setTimeout(() => {
          const newProgress = [...uploadProgress];
          newProgress[index] = progress;
          setUploadProgress(newProgress);
        }, 1000);
      });
    }
  };

  const handleSubmit = () => {
    const formData = {
      currentActionOwner,
      currentActionDescription,
      currentActionDeadline,
      notes,
      selectedFiles,
    };
    onSubmit(formData);
  };

  return (
    <Box p={6} borderWidth={1} borderRadius="md">
      <FormControl mb={4}>
        <FormLabel color={PRIMARY_COLOR}>Current Action Owner</FormLabel>
            <SeqDropDown options={users} placeholderText={"Current Action Owner"} onSelect={(option:SeqDropDownOptions) => {}}/>
        </FormControl>

      <FormControl mb={4}>
        <FormLabel color={PRIMARY_COLOR}>Current Action Description</FormLabel>
        <Textarea
          focusBorderColor={PRIMARY_COLOR} 
          placeholder="Enter Action Description"
          value={currentActionDescription}
          onChange={(e) => setCurrentActionDescription(e.target.value)}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel color={PRIMARY_COLOR}>Current Action Deadline</FormLabel>
        <Input
          focusBorderColor={PRIMARY_COLOR} 
          type="date"
          ref={dateInputRef}
          value={currentActionDeadline}
          onFocus={handleFocus}
          onChange={(e) => setCurrentActionDeadline(e.target.value)}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel color={PRIMARY_COLOR}>Notes</FormLabel>
        <Textarea
          focusBorderColor={PRIMARY_COLOR} 
          placeholder="Enter any notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel color={PRIMARY_COLOR}>Upload Files</FormLabel>
        <FileUploader width={"100%"} height={"150px"} onFileSelect={()=>{}}/>
        {/* File Upload Progress */}
        <List spacing={2}>
          {selectedFiles.map((file, index) => (
            <ListItem key={file.name}>
              <ListIcon as={FiFile} color="green.500" />
              {file.name}
              <Progress
                mt={2}
                value={uploadProgress[index]}
                size="sm"
                colorScheme={"blue"}
                hasStripe
              />
            </ListItem>
          ))}
        </List>
      </FormControl>
    </Box>
  );
};

export default CurrentActionForm;
