import { useState } from "react";
import {
  Box,
  Input,
  List,
  ListItem,
  VStack,
  FormControl,
} from "@chakra-ui/react";
import { PRIMARY_COLOR } from "../constant";

export interface SeqDropDownOptions {
  label: string;
  value: string;
}

interface SearchableDropdownProps {
  options: SeqDropDownOptions[];
  placeholderText: string,
  onSelect: Function
}

const SeqDropDown: React.FC<SearchableDropdownProps> = ({ options, placeholderText, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option: SeqDropDownOptions) => {
    onSelect(option);
    setSearchTerm(option.label);
    setIsFocused(false);
  };

  return (
    <VStack spacing={4} align="start"  w="100%">
      <FormControl>
        <Input
          placeholder={placeholderText}
          value={searchTerm}
          focusBorderColor={PRIMARY_COLOR}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
        />
        {isFocused && filteredOptions.length > 0 && (
          <Box
            border="1px solid"
            borderColor={PRIMARY_COLOR}
            borderRadius="md"
            mt={1}
            maxHeight="150px"
            overflowY="auto"
            width="100%"
            position="absolute"
            bg="white"
            zIndex="1000"
          >
            <List spacing={1}>
              {filteredOptions.map((option, index) => (
                <ListItem
                  key={index}
                  padding="8px"
                  cursor="pointer"
                  _hover={{ background: "gray.100" }}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </FormControl>
    </VStack>
  );
};

export default SeqDropDown;
