import { ChakraProvider } from "@chakra-ui/react";
import LoginPage from "./pages/LoginPage";
import Router from "./Router";
import { BrowserRouter as ReactRouter, Route, Routes } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import useAdmin from "./api/queries/useAdmin";
import QueryClient from "./api/QueryClient";
import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import { RegisterPage } from "./pages";
import { setUserEmail } from "./api/ApiService";
import Login from "./pages/Login";
import { PATHS } from "./constants/Constants";
import Dashboard from "./pages/Dashboard";
import theme from './theme';
import TechnologyDashboard from "./routes/TechnologyDashboard";
import UserManager from "./routes/UserManager";
import AcraManagement from "./routes/AcraManagement";

function AppInner() {
	const { data: user, error: userError, isLoading } = useAdmin.useUserProfile();

	useEffect(() => {
		if (user) {
			setUserEmail(user.upn);
		}
	}, [user]);

	if (window.location.pathname === "/register") {
		return (
			<ChakraProvider>
				<RegisterPage />
			</ChakraProvider>
		);
	}

	if (isLoading) {
		return <></>;
	}

	if (userError?.response?.status === HttpStatusCode.Unauthorized) {
		return (
			<ChakraProvider>
				<LoginPage />
			</ChakraProvider>
		);
	}

	if (!user) {
		return (
			<ChakraProvider>
				<LoginPage />
			</ChakraProvider>
		);
	}

	if (window.location.pathname === "/login" && user) {
		window.location.href = "/";
	}

	return (
		<ChakraProvider>
			<ReactRouter>
				<Router />
			</ReactRouter>
		</ChakraProvider>
	);
}

function App() {
	return (
		<QueryClientProvider client={QueryClient}>
			<ChakraProvider theme={theme}>
			<ReactRouter>
				<Routes>
					<Route path={PATHS.TECH_DASHBOARD} element={<TechnologyDashboard />} />
					<Route path={PATHS.DASHBOARD} element={<Dashboard />} />
					<Route path={PATHS.HOME} element={<Login />} />
					<Route path={PATHS.LOGIN} element={<Login />} />
					<Route path={PATHS.USER_MANAGER} element={<UserManager />} />
					<Route path={PATHS.ACRA_MANAGER} element={<AcraManagement />} />
				</Routes>
			</ReactRouter>
		</ChakraProvider>
		</QueryClientProvider>
	);
}

export default App;
