import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../components/Header';
import ApiSearchComponent from '../components/ApiSearchComponent';

export default function AcraManagement() {
  return (
    <Box>
        <Header/>
        <ApiSearchComponent/>
    </Box>
  )
}
