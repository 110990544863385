import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  Select,
  Textarea,
  HStack
} from "@chakra-ui/react";
import { EntityObject, TicketType } from "../../types/Ticketing/SeqTicket.type";
import SeqDropDown, { SeqDropDownOptions } from "../SeqDropDown";
import { PRIMARY_COLOR } from "../../constant";

interface User {
  userId: string;
  userName: string;
}

interface Entity {
  clientId: string;
  entityName: string;
}

interface TicketEntityFormProps {
  users: SeqDropDownOptions[];
  entities: SeqDropDownOptions[];
  onSubmit: (data: any) => void;
}

const TicketEntityForm: React.FC<TicketEntityFormProps> = ({
  users,
  entities,
  onSubmit
}) => {
  const [ticketType, setTicketType] = useState(TicketType.EXTERNAL);

  return (
    <Box p={6} borderWidth={1} borderRadius="md">
      <form onSubmit={()=>{}}>
        <FormControl as="fieldset" mb={4}>
          <FormLabel as="legend">Ticket Type</FormLabel>
          <RadioGroup
            defaultValue={ticketType}
            onChange={(value:TicketType) => setTicketType(value)}>
            <HStack spacing="24px">
              <Radio value="External">
                External
              </Radio>
              <Radio value="Internal">
                Internal
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={PRIMARY_COLOR}>Work Order Number</FormLabel>
          <Input type="text" placeholder="Enter Work Order Number" focusBorderColor={PRIMARY_COLOR} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={PRIMARY_COLOR}>Entity Name</FormLabel>
          <SeqDropDown options={entities} placeholderText={"Select Entity"} onSelect={(option:SeqDropDownOptions) => {}}/>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={PRIMARY_COLOR}>Ticket Raised By</FormLabel>
          <SeqDropDown options={users} placeholderText={"Select Entity"} onSelect={(option:SeqDropDownOptions) => {}}/>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={PRIMARY_COLOR}>Ticket Owner</FormLabel>
          <SeqDropDown options={users} placeholderText={"Select Entity"} onSelect={(option:SeqDropDownOptions) => {}}/>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={PRIMARY_COLOR}>Overall Date</FormLabel>
          <Input type="date" focusBorderColor={PRIMARY_COLOR} />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={PRIMARY_COLOR}>Ticket Description</FormLabel>
          <Textarea placeholder="Enter Ticket Description" focusBorderColor={PRIMARY_COLOR} />
        </FormControl>
      </form>
    </Box>
  );
};

export default TicketEntityForm;
